import { MonoTypeOperatorFunction, Observable, ObservableInput, ObservedValueOf, Subscription, of } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';


/**
 * pipe operator, which will trigger a given function, in which the first parameter is the value of the observable on the last time
 * at the same position of the pipe or if it is the first time then it is undefined
 * 2nd parameter = the current value
 * 3rd parameter = array (stack) of all remembered values so far
 */
export function rememberValue<T = any>(tapFnRememberedValues: (lastVal: T, currentVal?: T, allValues?: T[]) => void, offset: number = 0): MonoTypeOperatorFunction<T> {

  return (observable: Observable<T>) => new Observable<T>(observer => {
    // this function will be called each time this Observable is subscribed to.

    // array, which saves all values
    let _arr: T[] = [];

    const getLast = (offset: number = 0): T => {
      if (offset >= _arr.length) {
        return void 0;
      }
      return _arr[_arr.length - 1 - offset];
    };

    const subscription = observable.subscribe({
      next: value => {
        const lastVal = getLast(offset);
        tapFnRememberedValues(lastVal, value, _arr);
        _arr.push(value);
        observer.next(value);
      },
      error: err => observer.error(err),
      complete: () => observer.complete()
    });

    // the return value is the teardown function, which will be invoked when the new Observable is unsubscribed from.
    return () => {
      _arr = void 0;
      subscription.unsubscribe();
    };
  });
}


/**
 * rxjs operator, which "fires" the observable, all tasks in the pipe line before this operator
 * and returns a copied source.
 * the name comes from the military jargon "fire and forget" (but subscribing is still possible)
 * Note: should note be used with subjects
 */
export function fire() {
  return function<T>(source: Observable<T>): Observable<T> {
    const syncValue = getSyncValue(source);
    return of(syncValue);
  };
}

export function catchErrorAndComplete<T>(handling: 'no' | 'log' | 'warn' | 'error' | 'callback' = 'no', callback?: (err: any, caught: Observable<T>) => void) {
  return function(source: Observable<T>): Observable<T> {
    return source.pipe(
      catchError((err: any, caught: Observable<T>) => {

        if (['log', 'warn', 'error'].includes(handling)) {
          console[handling](err);
        }

        if(handling === 'callback' && !!callback) {
          callback(err, caught);
        }

        return of(null) as Observable<T>;
      })
    );
  };
}

export function debug(...args: any[]) {
  return function<T>(source: Observable<T>) {
    let debugCounter = 0;
    return source.pipe(tap(value => {
      const msg = 'Debug[' + (++debugCounter) + ']';
      if (args.length) {
        console.log(msg, ...args, value);
      } else {
        console.log(msg, value);
      }
    }));
  };
}


export function watch<O1 extends ObservableInput<any>>(sources: [O1]): Observable<[ObservedValueOf<O1>]>;
export function watch<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>>(sources: [O1, O2]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>]>;
export function watch<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>>(sources: [O1, O2, O3]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>]>;
export function watch<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>, O4 extends ObservableInput<any>>(sources: [O1, O2, O3, O4]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>]>;
export function watch<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>, O4 extends ObservableInput<any>, O5 extends ObservableInput<any>>(sources: [O1, O2, O3, O4, O5]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>]>;
export function watch<O1 extends ObservableInput<any>, O2 extends ObservableInput<any>, O3 extends ObservableInput<any>, O4 extends ObservableInput<any>, O5 extends ObservableInput<any>, O6 extends ObservableInput<any>>(sources: [O1, O2, O3, O4, O5, O6]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>]>;
export function watch<O extends ObservableInput<any>>(sources: O[]): Observable<ObservedValueOf<O>[]>;

/**
 * similiar to combineLatest but with the difference that the returned observable triggers with every next value of any watched
 * observable of the source array. The combination operator watch() does NOT wait until every source observable
 */
export function watch(sources: Observable<any>[]): Observable<any[]> {
  return new Observable<any>(observer => {
    const subscribtion = new Subscription();
    const values = new Array(sources.length);
    let active: number = sources.length;

    sources.forEach((source, index) => subscribtion.add(
      source.subscribe({
        next: value => {
          values[index] = value;
          observer.next(values);
        },
        error: error => {
          observer.error(error);
          subscribtion.unsubscribe();
        },
        // only complete when all input observables have completed
        complete: () => (--active === 0) && observer.complete()
      })
    ));

    return {
      unsubscribe: () => subscribtion.unsubscribe()
    };
  });
}


/**
 * returns the current value of an observable or observable-like in a synchronous way. An acessor function can specify the wanted value
 * Note: Subjects do not store a value - they only signalize their subscribers in case of a next()
 */
export function getSyncValue<T, R = T>(obs: Observable<T>, acessorFunc?: (type: T) => R): R {

  let tmp: R;

  const sub = obs.pipe(take(1)).subscribe(val => {
    tmp = typeof acessorFunc === 'function' ? acessorFunc(val) : (val as unknown as R);
  });

  sub.unsubscribe();

  return tmp;
}


export function getNextTruthyValue<T>(obs: Observable<T>, callBackFn: (value: T) => void) {
  const s = obs.pipe(filter(_ => !!_), take(1)).subscribe(val => callBackFn(val));
  return {
    unsubscribe: () => s.unsubscribe()
  };
}
